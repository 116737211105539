/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // Check to see if there's an updated version of service-worker.js with
      // new files to cache:
      // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-registration-update-method
      if (typeof registration.update === 'function') {
        registration.update();
      }

      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }
  // Your custom JavaScript goes here
   $('.burger-icon').click(function(e){
      e.preventDefault();
     $('body').toggleClass('open');
  })
   $('header').each(function(){
    var ct=0;
    var hh = $('header').offset().top;
    $(window).scroll(function(){
      var top = $(window).scrollTop(),
          h = $(window).height(),
          header = $('header');
      if(!$('.nav-menu').hasClass('open')){
        if(top > hh){
          header.addClass('fixed');
          $('.cart').removeClass('in');
        }else{
          header.removeClass('fixed')
        }
        if(ct> top){
          header.addClass('sticky');
        }else{
          header.removeClass('sticky');
        }

        if(top>h){
          jQuery('.back-to-top').removeClass('h');
        }else{
          jQuery('.back-to-top').addClass('h');
        }

        ct = top;
      }
    });
   })
   $('footer').find('h3').each(function(){
    $(this).click(function(){
      $(this).parent().toggleClass('open');

    })
   })

  function dropdown(){
    var w =$(window).width(),p = $('.dropdown > a');
    if(w<768){
      p.click(function(e){
        e.preventDefault();
        p.parent().toggleClass('in');
      })
    }
  }
  dropdown();
    function slidermobile(){
    var w =$(window).width(),slider = $('.slider-xs');
    if(w<768){
      slider.addClass('owl-carousel').removeClass('ns');
      slider.owlCarousel({
          loop:false,
          dots:true,
          nav: false,
          responsive : {
            0 : {
                items: 1
            },
            768 : {
                items: 3
            },
          }
      })
    }else{
      slider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
      slider.find('.owl-stage-outer').children().unwrap();
    }
  }
  slidermobile();
  $(window).resize(function(){
    dropdown();
    slidermobile();
  })
  $('.slider').each(function(){
    var t = $(this),
        item = t.attr('data-items') ? t.attr('data-items') : 1,
        navs = t.attr('data-nav') && t.attr('data-nav')=="no" ? false : true,
        dot = navs ? false : true,
        loops = dot ? true : false;
    t.addClass('owl-carousel').each(function(){
      var t = $(this);
        t.owlCarousel({
            loop: loops,
            dots: dot,
            nav: navs,
            items: item,
            navText : ["<span class='fa fa-angle-left'></span>","<span class='fa fa-angle-right'></span>"],
            autoplay: true,
            autoplayTimeout: 4000,
        })
    })
  })

  $('select').addClass('select').selectpicker({
    style: 'select-control',
    size: 4
  });
  /* */
  var hw = 0;
    function rewHeight(i,item){
      var n = i+1,c=n%3==0? 2 : n%3-1;
        for(var x = i-c;i>=x;i--){
          item.eq(i).height(hw+30);
        }
      hw = 0;

    }

    function autoHeight(item){
      var w = $(window).width(),
          length = item.length;
      if(w>=768){
        item.each(function(i){
          $(this).removeAttr('style');
          var h = $(this).height(),n=i+1;
          //$(this).attr('data-height',h);
          if(h > hw){
            hw = h;
          }
          if(n%3 == 0|| n ==length){
            rewHeight(i,item);
          }
        })
      }else{
        item.each(function(){
          $(this).height('auto')
        })

      }
    }
    var testi = $('.testimonial, .core-items').find('article');
    autoHeight(testi);

    var html = "<a class='back-to-top h'><i class='fa fa-angle-up'></i></a>";
      jQuery('body').append(html);
      jQuery('.back-to-top').on('click',function(){
        jQuery("html, body").animate({ scrollTop: 0 },'slow');
    });

    $('.scroll').click(function(e){
      e.preventDefault();
      var t = $(this).attr('href'),top;
      top= t=="#" ? 0:$(t).offset().top- 70;
      $('body').removeClass('open');
      $("html, body").animate({ scrollTop: top+"px" },'slow');
   });

})();


jQuery('img.svg').each(function(i){
      var $img = jQuery(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');

      jQuery.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg');

          // Add replaced image's ID to the new SVG
          if(typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
          }
          // Add replaced image's classes to the new SVG
          if(typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass+' replaced-svg');
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr('xmlns:a');

          // Replace image with new SVG
          $img.replaceWith($svg);

      }, 'xml');

  });